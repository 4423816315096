.image-wapper {
    position: sticky;
    top: 0;
    height: 100vh;

    .image-block {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0;
        &.-on {
            animation: fadein-keyframes .5s ease 0s 1 forwards;
        }
        @keyframes fadein-keyframes{
            0%{
              opacity: 0;
            }
            100%{
              opacity: 1;
            }
          }
        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: calc(100% - 19.2rem);
        }
    }

}
.option-wapper {
    padding-top: 0;
}