.input-wrap {
    input {
        padding: 0;
        border: none;
        border-radius: 0;
        outline: none;
        background: none;
        box-sizing: border-box;
        resize: none;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
    input:focus {
        border: 2px solid #4c91ec;
    }
    .input-text {
        font-size: 16px;
        font-family: 'Manrope', sans-serif;
        border-radius: 26px;
        color: #5D5D5F;
        background-color: #F4F4F4;
        border: 1px solid #F4F4F4;
        padding: 10px 15px 10px 15px;
        width: 100%;
        transition: border .25s cubic-bezier(.5,.25,.25,.75);
    }
}
