.input-block {
    padding: 5.2rem 0;
    min-height: 100vh;
    &.issue {
      padding-bottom: 35rem;
    }
}

.option-head {
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 22px;
}

.option-item h1.serviceName {
    font-size: 29px;
    line-height: 1.25;
    margin-bottom: 51px;
    margin-top: 54px;
    text-align: center;
}

.option-item ul.option-selectName {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    padding: 0 24px 0 15px;
}
.radio-input {
    clip: rect(1px,1px,1px,1px);
    position: absolute;
}
input, select, textarea {
    -webkit-appearance: none;
    appearance: none;
    background: none;
    border: none;
    border-radius: 0;
    box-sizing: border-box;
    outline: none;
    padding: 0;
    resize: none;
}

.radio-label {
    border: 1px solid #a2a2a4;
    border-radius: 26px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding: 17px 24px 17px 15px;
    width: 100%;
}

.radio-input:checked+.radio-label {
    border: 2px solid #3e6ae1;
    padding: 16px 23px 16px 14px;
}

$color1: #f4f4f4;
$color2: #3197EE;

.radio {
  margin: 0.5rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label-def {
      &:before {
        content: '';
        background: $color1;
        border-radius: 100%;
        border: 1px solid darken($color1, 25%);
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-right: 1em; 
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label-def {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 4px $color1;
        }
      }
    }
    &:focus {
      + .radio-label-def {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $color1;
          border-color: darken($color1, 25%);
          background: darken($color1, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.text-picker {
  margin-top: 8rem;
}
:root {
  --amplify-primary-color: #577dd1;
  --amplify-primary-tint: #577dd1;
  --amplify-primary-shade: #577dd1;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  z-index: 999;
}