*{
    box-sizing: border-box;
  }
  body{
    position: relative;
    margin: 0px;
    font-family: 'Manrope', "Helvetica Neue",Arial,"Hiragino Kaku Gothic ProN","Hiragino Sans",Meiryo,sans-serif;
    font-size: 10px;
    /* font-family: 'Manrope', sans-serif; */
  }
  body.-fixed{
    overflow-y: hidden;
  }
  p {
    font-weight: 400;
    font-size: 1rem;
  }
  h1,h2,h4,h6,p{
    margin: 0;
  }
  ul{
    margin: 0;
    padding: 0;
  }
  li{
    list-style: none;
    font-size: 1.4rem;
  }
  a {
    text-decoration: none;
  }
  input,textarea,select{
    padding: 0;
    border: none;
    border-radius: 0;
    outline: none;
    background: none;
    box-sizing: border-box;
    resize: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  .header {
    position: fixed;
    height: 52px;
    width: 100%;
    z-index: 555;
    padding: 0 48px;
    backdrop-filter: blur(48px);
    background-color: rgba(255, 255, 255, 0.75);

    .logo {
        width: 89px;
        height: 34px;
        margin-top: 18px;
        background-image: url(./images/logo.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    .userList{
        position: absolute;
        top: 18px;
        right: 30px;
        display: flex;
        align-items: center;

        p.name{
        margin-right: 18px;
        color: #ce8585;
        font-weight: 600;
      }
       p.logout{
        color: #555;
        border: 1px solid #e0e0e0;
        padding: 5px 14px;
        font-size: 12px;
        border-radius: 20px;
        font-weight: 600;
      }
    }
  }
  .contents {
    display: grid;
    grid-template-columns: 1fr 320px;
    column-gap: 48px;
    padding-right: 120px;
}

[data-amplify-authenticator] {
  margin-top: 8rem;
  --amplify-components-button-primary-background-color: rgb(41, 26, 238);
  --amplify-components-button-primary-active-background-color: rgb(47, 19, 230);
  --amplify-components-button-primary-visited-background-color: rgb(37, 16, 224);
  --amplify-components-button-primary-focus-background-color: rgb(61, 14, 231);
  --amplify-components-button-primary-hover-background-color: rgb(113, 107, 238);
  --amplify-components-button-link-color: rgb(72, 30, 210);
  --amplify-components-button-link-hover-color: rgb(57, 30, 210);
  --amplify-components-button-link-focus-color: rgb(57, 30, 210);
  --amplify-components-button-link-active-color: rgb(193, 185, 255);
  --amplify-components-button-link-hover-background-color: rgb(191, 185, 255);
  --amplify-components-button-link-active-background-color: rgb(108, 96, 244);
}
.signin-title {
  font-size: 2rem;
  padding-bottom: 1rem;
  text-align: center;
}

.agreement {
  .description {
    border: 1px solid #ccc;
    padding: 0.5rem;
  }
  .title {
    color:rgb(72, 56, 219);
    font-size: 1.2rem;
    font-weight: bold;
  }
  ul {
    margin-bottom: 1rem;
    li {
      font-size: 0.9rem;
      padding: 0.2rem 0;
    }
  }
}